import * as React from "react"
import { Fragment } from 'react'
import { Link, graphql } from "gatsby"
import { Popover, Transition } from '@headlessui/react'
import { StaticImage } from "gatsby-plugin-image"
import Seo from '../../components/seo'

const ContactPage = ({ data }) => {
  const entry = data.prismicContactPage
  //console.log('entry: ', entry);
  if (!entry) return null
  return (
  <Popover className="relative overflow-hidden">
  {({ open }) => (
  <div>
    <Transition
      show={open}
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-200 ease-out"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
      >
      <Popover.Panel focus static className="fixed h-full z-50 top-0 inset-x-0 transition">
        <div className="w-full h-full mx-auto relative">

          <div className="fixed inset-0 w-full h-full bg-dark-grey"></div>

          <div className="w-full pt-12 relative">
            <div className="w-10/12 mx-auto max-w-screen-xl">
              <div class="flex-none sm:flex justify-between relative">
                <Link to="/" className="focus:outline-none"><StaticImage placeholder="none" src="../images/logo_reversed_without_tag.png" alt="dallers" className="w-52 focus:outline-none" style={{ display: "block" }} imgStyle={{ objectFit: "contain" }} /></Link>
                <Popover.Button className="absolute right-0 top-0 focus:outline-none">
                  <span className="sr-only">Close menu</span>
                  <div className="bg-orange text-white p-6 rounded-full flex items-center justify-center relative">
                    <svg xmlns="http://www.w3.org/2000/svg" className="absolute h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                </Popover.Button>
              </div>
            </div>
          </div>
          
          <div className="w-full pt-24 sm:pt-36 relative">
            <div className="w-10/12 mx-auto max-w-screen-xl">
              <div class="flex-none sm:flex gap-12 justify-between">
                <div className="w-full sm:w-6/12 space-y-3 sm:space-y-6">
                  <div>
                    <Link to="/about/" className="font-verdana text-3xl sm:text-5xl font-bold text-white mb-6 hover:text-white hover:underline">
                      About
                    </Link>
                  </div>
                  <div>
                    <Link to="/founder/" className="font-verdana text-3xl sm:text-5xl font-bold text-white mb-6 hover:text-white hover:underline">
                      Founder
                    </Link>
                  </div>
                  <div>
                    <Link to="/values/" className="font-verdana text-3xl sm:text-5xl font-bold text-white mb-6 hover:text-white hover:underline">
                      Values
                    </Link>
                  </div>
                  <div>
                    <Link to="/services/" className="font-verdana text-3xl sm:text-5xl font-bold text-white mb-6 hover:text-white hover:underline">
                      Services
                    </Link>
                  </div>
                  <div>
                    <Link to="/contact/" className="font-verdana text-3xl sm:text-5xl font-bold text-white mb-6 hover:text-white hover:underline">
                      Contact
                    </Link>
                  </div>
                </div>
                <div className="w-full mt-12 sm:mt-0 sm:w-6/12 justify-center">
                  <div>
                    <p className="font-verdana text-lg text-mid-grey leading-normal mb-12 hidden sm:inline-block">Take a look at what we do here and drop us a line.</p>
                    <p className="font-verdana text-lg text-orange mb-3 font-bold"><a href="tel:+642102422951" title="+64 (0)21 024 22951">+64 (0)21 024 22951</a><br />
                    <a href="mailto:don@dallersconsult.com" title="don@dallersconsult.com" rel="nofollow noopener">don@dallersconsult.com</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popover.Panel>
    </Transition>
    
    <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname="/contact/" /> 

    <div className="w-full py-12 relative">

      <div className="absolute rounded-full w-96 h-96 bg-light-grey -left-20 -top-20 z-10"></div>
      <div className="absolute rounded-full w-48 h-48 bg-light-grey -right-24 z-10"></div>

      <div className="w-10/12 mx-auto max-w-screen-xl relative z-20">
        <Link to="/"><StaticImage src="../images/logo_without_tag.png" alt="dallers" className="w-52 block" imgStyle={{ objectFit: "contain" }} /></Link>
        <Popover.Button className="absolute right-0 top-0 focus:outline-none">
          <span className="sr-only">Open menu</span>
          <div className="bg-orange text-white hover:bg-dark-grey hover:text-white p-6 rounded-full flex items-center justify-center relative">
            <svg xmlns="http://www.w3.org/2000/svg" className="absolute h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </div>
        </Popover.Button>
      </div>
    </div>

    <div className="w-full pb-24 relative z-20">
      <div className="w-10/12 mx-auto max-w-screen-xl">
        <div className="flex-none sm:flex gap-24 justify-between">
          <div className="w-full sm:w-4/12">
           &nbsp;
          </div>
          <div className="w-full sm:w-8/12">
            <p className="font-verdana text-lg font-bold text-dark-grey mb-12">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
              </svg> Contact
            </p>
            <h1 className="font-verdana font-bold text-orange text-3xl sm:text-6xl mb-6">Get in touch</h1>
              <div className="font-verdana prose prose-lg leading-normal text-dark-grey">
                <p>If you like what you’ve heard, or curious to find out more, please give us a call or drop us an email. We’d love to hear from you.</p>
                <p><a href="mailto:don@dallersconsult.com" title="don@dallersconsult.com" rel="nofollow noopener">don@dallersconsult.com</a></p>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div className="w-full pt-12 bg-dark-grey relative z-20">
      <div className="w-10/12 mx-auto max-w-screen-xl">
        <div className="flex-none sm:flex justify-between">
          <div className="w-full sm:w-6/12">
            <Link to="/"><img src="../images/logo_reversed_without_tag.png" alt="dallers" className="w-52 block" /></Link>
          </div>
          <div className="w-full sm:w-6/12 mt-12 sm:mt-0">
            <div className="flex-none sm:flex justify-between">
              <div className="w-full sm:w-6/12">
                <ul>
                  <li className="font-verdana font-bold text-lg text-orange mb-1"><Link to="/about/">About</Link></li>
                  <li className="font-verdana font-bold text-lg text-orange mb-1 "><Link to="/founder/">Founder</Link></li>
                  <li className="font-verdana font-bold text-lg text-orange mb-1 "><Link to="/values/">Values</Link></li>
                  <li className="font-verdana font-bold text-lg text-orange mb-1"><Link to="/services/">Services</Link></li>
                </ul>
              </div>
              <div className="w-full sm:w-6/12 text-left sm:text-right mt-6 sm:mt-0">
                <div className="font-verdana font-bold text-lg text-white max-w-none">
                  <a href="tel:+642102422951" title="+64 (0)21 024 22951" rel="nofollow noopener">+64 (0)21 024 22951</a><br />
                  <Link to="/contact/">Contact&nbsp;<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" /></svg></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="w-full pt-12 pb-12 bg-dark-grey relative z-20">
      <div className="w-10/12 mx-auto max-w-screen-xl">
        <div className="flex-none sm:flex justify-between">
          <div className="w-full sm:w-6/12">
            <div className="font-verdana prose prose-lg text-light-grey max-w-none">
              <p>&copy; 2021</p>
            </div>
          </div>
          <div className="w-full sm:w-6/12 text-left sm:text-right">
            <div className="font-verdana text-lg text-light-grey max-w-none">
              <Link to="/terms/">Terms of use</Link> &nbsp;<span className="text-orange">&bull;</span>&nbsp; <Link to="/privacy/">Privacy</Link>
            </div>
          </div>
        </div>
        </div>
    </div>

  </div>
  )}
  </Popover>
  )
}

export const query = graphql`
query {
  prismicContactPage {
    data {
      text {
        raw
      }
      page_title
      meta_description
      heading {
        raw
        text
      }
      image {
        gatsbyImageData
        fluid(imgixParams: {maxHeight: 600, maxWidth: 800}) {
            src
        }
    }
    }
  }
}
`

export default ContactPage
